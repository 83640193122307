export default {
  successText: null,
  errorText: null,

  success(text) {
    this.successText = text
  },

  error(text) {
    this.errorText = text
  },

  hasNotification() {
    this.successText || this.errorText
  },

  clearNotification() {
    this.successText = null
    this.errorText = null
  }
}
